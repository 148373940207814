import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["mainButton", "icon", "dropdownList"];

  connect() {
    console.log("Multisitable Heritage Icon Controller connected!");
    this.linkTypesIconsValue = {
      "Descendants": { icon: this.faRegularCircleCheck(), text: "Descendants" },
      "Children": { icon: this.faRegularCircleCheck(), text: "Children" },
      "Single": { icon: this.faRegularCircleCheck(), text: "Single" },
      "No Access": { icon: this.faRegularCircleXmark(), text: "No Access" },
      "No Rule": { icon: this.faRegularCircleCheck(), text: "No Rule" }
    }
  }

  selectOption(event) {
    event.preventDefault()
    const selectedOption = event.target.dataset.value
    const selectedData = this.linkTypesIconsValue[selectedOption]

    if (!selectedData) {
      return;
    }

    this.mainButtonTarget.textContent = selectedData.text
    this.iconTarget.innerHTML = selectedData.icon

    // Find all the elements that have the same ancestry as the current element
    switch (selectedData.text) {
      case 'No Rule':
        event.currentTarget.closest('.multisitable-company').querySelectorAll('.input-link-type')[0].value = 'no_rule'
        var spelements = event.currentTarget.closest('.accordion-body').querySelectorAll('.multisitable-company[data-ancestry^="' + event.currentTarget.closest('.multisitable-company').dataset['ancestry'] + '"]')
      case 'No Access':
        event.currentTarget.closest('.multisitable-company').querySelectorAll('.input-link-type')[0].value = 'no_access'
        var spelements = event.currentTarget.closest('.accordion-body').querySelectorAll('.multisitable-company[data-ancestry^="' + event.currentTarget.closest('.multisitable-company').dataset['ancestry'] + '"]')
        break;
      case 'Single':
        event.currentTarget.closest('.multisitable-company').querySelectorAll('.input-link-type')[0].value = 'single'
        var spelements = event.currentTarget.closest('.accordion-body').querySelectorAll('.multisitable-company[data-ancestry^="' + event.currentTarget.closest('.multisitable-company').dataset['ancestry'] + '"]')
        break;
      case 'Descendants':
        event.currentTarget.closest('.multisitable-company').querySelectorAll('.input-link-type')[0].value = 'descendants'
        var spelements = event.currentTarget.closest('.accordion-body').querySelectorAll('.multisitable-company[data-ancestry^="' + event.currentTarget.closest('.multisitable-company').dataset['ancestry'] + '"]')
        break;
      case 'Children':
        event.currentTarget.closest('.multisitable-company').querySelectorAll('.input-link-type')[0].value = 'children'
        var spelements = event.currentTarget.closest('.accordion-body').querySelectorAll('.multisitable-company[data-ancestry^="' + event.currentTarget.closest('.multisitable-company').dataset['ancestry'] + '"][data-depth="' + (parseInt(event.currentTarget.closest('.multisitable-company').dataset['depth']) + 1) + '"]')
        break;
    }
    var filteredSpelements = Array.from(spelements).filter(element => element != event.currentTarget.closest('.multisitable-company'))
    switch (selectedData.text) {
      case 'No Rule':
      case 'No Access':
      case 'Single':
        filteredSpelements.forEach(function (element) {
          element.querySelector('.link-type-icon').innerHTML = this.linkTypesIconsValue['No Access'].icon
          element.querySelectorAll('.input-link-type')[0].value = ''
          element.dataset['linkType'] = ''
        }, this)
        break;
      case 'Children':
      case 'Descendants':
        filteredSpelements.forEach(function (element) {
          element.querySelector('.link-type-icon').innerHTML = this.linkTypesIconsValue['Single'].icon
          element.querySelectorAll('.input-link-type')[0].value = ''
          element.dataset['linkType'] = ''
        }, this)
        break;
    }
  }

  faRegularCircleCheck() {
    return `
      <svg class="fa-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="fill: #58B596;">
        <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/>
      </svg>
    `;
  }

  faRegularCircleXmark() {
    return `
      <svg class="fa-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="fill: #FF4E4F;">
        <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"/>
      </svg>
    `;
  }
}
